<template>
  <div class="ProductsFullColumn">
    <div
      class="productcard"
      v-for="(productCardFull, index) in productCardFull"
      :key="'productCardFull' + index"
    >
      <b-carousel :interval="4000">
        <div class="productcard__gloss z2"></div>
        <b-carousel-slide>
          <template v-slot:img>
            <!-- <img
              :src="require('@/assets/images/' + productCardFull.carouselImg1)"
              alt="image slot"
            /> -->
            <img
              :src="require('@/assets/images/steve-furgal_3.jpg')"
              alt="image slot"
            />
          </template>
        </b-carousel-slide>
      </b-carousel>
      <div class="productcard__desc">
        <h2>{{ productCardFull.title }}</h2>
        <!-- <p>{{ productCardFull.desc }}</p> -->
        <p v-html="epitch['productCardDescriptionPackage6']" />
        <!-- <ul v-html="productCardFull.points"></ul> -->
        <ul>
          <li
            v-for="(packageData, index) in epitch['package6']"
            :key="'package' + index"
          >
            {{ packageData["description"] }}
          </li>
        </ul>
        <div class="productcard__desc__links">
          <a
            :href="productCardFull.exploreLink"
            target="_blank"
            class="productcard__desc__links__btn"
          >
            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.012 12.024l6.012-6.012L6.012 0 4.95 1.062l4.194 4.212H0V6.75h9.144L4.95 10.962z" fill-rule="nonzero"/></svg>
            <span>Explore</span>
          </a>
          <!-- </a> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductsFullColumn",
  props: {
    productCardFull: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      epitch: null,
    };
  },
  created() {
    let pages = this.$store.state.epitch.ePitch.pages;
    if (typeof pages !== "undefined") {
      pages.forEach((page) => {
        if (page.id === "1") {
          this.epitch = JSON.parse(page.copy);
        }
        // window.console.log("epitch", this.epitch);
      });
    }
  },
};
</script>

<style lang="scss">
.ProductsFullColumn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // @media screen and (min-width: 1699px) {
  //   display: none;
  // }
  @media screen and (max-width: $md) {
    display: none;
  }
  .productcard {
    width: 100%;
    height: 280px;
    display: flex;
    position: relative;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.15);
    margin: 13px 0;
    @media screen and (max-width: $xl) {
      height: 240px;
    }
    @media screen and (max-width: $lg) {
      height: 190px;
    }
    .carousel {
      width: 49%;
      img {
        width: 100%;
      }
    }
    &__gloss {
      position: absolute;
      top: 0;
      left: -23%;
      width: 40%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.25);
      transform: skewX(-20deg);
    }
    &__desc {
      background-color: #fff;
      width: 51%;
      position: relative;
      h2 {
        font-family: "interstate", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 17px;
        color: $text-black;
        margin-top: 35px;
        padding-left: 50px;
        @media screen and (max-width: $xl) {
          margin-top: 17px;
        }
        @media screen and (max-width: $lg) {
          padding-left: 25px;
          font-size: 14px;
          margin-top: 12px;
        }
      }
      p {
        font-style: italic;
        font-size: 14px;
        color: $text-grey;
        margin-top: 10px;
        padding-left: 50px;
        line-height: 1.5rem;
        @media screen and (max-width: $lg) {
          padding-left: 25px;
          margin-top: 5px;
          font-size: 12px;
        }
      }
      ul {
        font-family: "interstate", sans-serif;
        padding-left: 68px;
        margin-top: 30px;
        color: $text-grey;
        font-size: 13px;
        font-style: italic;
        font-weight: 500;
        @media screen and (max-width: $xl) {
          margin-top: 15px;
        }
        @media screen and (max-width: $lg) {
          padding-left: 43px;
          font-size: 12px;
          margin-top: 5px;
        }
        li:nth-child(1) {
          margin: 5px 0;
        }
        li:nth-child(2) {
          margin: 5px 0;
        }
      }
      &__links {
        box-shadow: 0px 0 20px rgba(0, 0, 0, 0.1);
        width: 100%;
        position: absolute;
        bottom: 0;
        &__btn {
          display: flex;
          align-items: center;
          justify-content: center;
          border-right: 1px solid #c9c9c9;
          background-color: #fff;
          height: 71px;
          padding-bottom: 2px;
          transition: all 0.2 ease-in-out;
          @media screen and (max-width: $lg) {
            height: 50px;
          }
          &:hover {
            text-decoration: none;
            &:after {
              border-bottom: 5px solid $cta-blue;
            }
            svg {
              fill: $cta-blue;
            }
            span {
              text-decoration: none;
              color: $cta-blue;
            }
          }
          &:last-child {
            border-right: none;
          }
          &:after {
            content: "";
            position: absolute;
            bottom: 0;
            display: block;
            margin: 0 auto;
            width: 100%;
            border-bottom: 5px solid $text-black;
            transition: all 0.2s ease-in-out;
          }
          svg {
            margin-right: 10px;
            margin-left: -4px;
            fill: #bbb;
            transition: all 0.2s ease-in-out;
          }
          span {
            font-family: "interstate", sans-serif;
            font-weight: 700;
            font-style: normal;
            color: $text-black;
            font-size: 11px;
            text-transform: uppercase;
            transition: all 0.2s ease-in-out;
          }
        }
      }
    }
  }

  /* Bootstrap Overrides */
  .carousel-control-prev {
    z-index: 3 !important;
  }

  .carousel-control-next-icon {
    background-image: url("~@/assets/icons/home/icon_gallery-arrow.svg");
  }

  .carousel-control-prev-icon {
    background-image: url("~@/assets/icons/home/icon_gallery-arrow.svg");
    transform: rotate(180deg);
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 18px;
    height: 34px;
  }

  .carousel-indicators {
    bottom: 22px;
  }

  .carousel-indicators li {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 8px;
    opacity: 1;
    background-color: #fff;
    border: 1px solid #fff;
  }

  .carousel-indicators li.active {
    background-color: transparent;
  }

  .carousel-indicators li:focus {
    outline: none;
  }
}
</style>