<template>
  <div>
    <div class="ProductsHalfColumn" v-if="prices.length > 0">
      <div
        class="productcard"
        v-for="(productCard, index) in dynamicProductCard"
        :key="'productCard' + index"
        :class="
          productCard.className + ' ' + prices[productCard.backendId].priceClass
        "
      >
        <b-carousel :interval="4000" controls indicators>
          <div class="productcard__gloss z2"></div>
          <b-carousel-slide
            v-for="(slideImg, index) in productCard.slideImgs"
            :key="'slideImg' + index"
          >
            <template v-slot:img>
              <img
                :src="require('@/assets/images/' + slideImg.carouselImg)"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
          <div
            v-if="prices[productCard.backendId].pricingType == 'inquire'"
            class="productcard__price__mobile"
          >
            <p>Inquire for Pricing</p>
          </div>
          <div v-else class="productcard__price__mobile">
            <p>
              Price from
              <span>{{
                prices[productCard.backendId].pricingCost | toCurrency
              }}</span>
            </p>
          </div>
        </b-carousel>
        <div
          v-if="prices[productCard.backendId].pricingType == 'inquire'"
          class="productcard__price"
        >
          <!-- <p v-html="productCard.price"></p> -->
          <p>Inquire for Pricing</p>
        </div>
        <div v-else class="productcard__price">
          <!-- <p v-html="productCard.price"></p> -->
          <p>
            Price from
            <span>{{
              prices[productCard.backendId].pricingCost | toCurrency
            }}</span>
          </p>
          <!-- <p></p> -->
        </div>
        <div class="productcard__desc">
          <h2>{{ productCard.title }}</h2>
          <!-- <p v-html="productCard.desc"></p> -->
          <p
            v-html="
              epitch[
                `productCardDescriptionPackage${productCard.backendId + 1}`
              ]
            "
          ></p>
          <h3>{{ productCard.header }}</h3>
          <ul>
            <li
              v-for="(packageData, index) in epitch[
                `package${productCard.backendId + 1}`
              ]"
              :key="'package' + index"
            >
              {{ packageData["description"] }}
            </li>
          </ul>
        </div>
        <div class="productcard__links">
          <a
            v-show="productCard.videoActive"
            @click="productCard.modalVideo = !productCard.modalVideo"
            class="productcard__links__btn"
          >
            <svg width="8" height="11" viewBox="0 0 8 11" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M0 10.75l8-5-8-5z" fill-rule="nonzero"/></svg>
            <span>Video</span>
          </a>
          <!-- <a
            class="productcard__links__btn noshow"
            @click="inquireModalShow = !inquireModalShow"
          > -->
          <a
            class="productcard__links__btn noshow"
            @click="openLightbox(), buyFloodlight(productCard.inquireTracking)"
          >
            <svg width="24" height="19" viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'inquireNow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.094 18.9c.637 0 1.181-.236 1.631-.707.45-.472.675-1.028.675-1.67V2.377c0-.642-.225-1.198-.675-1.67A2.173 2.173 0 0021.094 0H2.306C1.67 0 1.125.236.675.707A2.338 2.338 0 000 2.377v14.146c0 .642.225 1.198.675 1.67.45.471.994.707 1.631.707h18.788zM11.7 8.29L2.306 2.377h18.788L11.7 8.29zm9.394 8.233H2.306V4.753L11.7 10.61l9.394-5.857v11.77z" fill-rule="nonzero"/></svg>
            <span>Inquire Now</span>
          </a>
          <router-link
            v-if="productCard.bookLink == '#'"
            :to="{ path: productCard.name, hash: '#selectDateAnchor' }"
            class="productcard__links__btn inquire buy"
          >
            <div class="holder" @click="buyFloodlight(productCard.buyTracking)">
              <svg width="9" height="16" viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.88 16v-1.92c.934-.185 1.67-.526 2.207-1.024.609-.555.913-1.266.913-2.133 0-.797-.212-1.472-.637-2.027-.354-.455-.87-.846-1.55-1.173-.495-.242-1.153-.47-1.973-.683-.977-.256-1.663-.533-2.06-.832-.396-.299-.594-.661-.594-1.088 0-.498.213-.9.637-1.205.425-.306 1.005-.459 1.74-.459 1.444 0 2.187.626 2.23 1.877h1.952c-.028-.796-.276-1.493-.743-2.09-.51-.64-1.217-1.067-2.122-1.28V0H3.226v1.92c-.891.185-1.613.548-2.165 1.088A2.85 2.85 0 00.17 5.12c0 .967.382 1.764 1.146 2.39.665.54 1.663.966 2.993 1.28 1.005.241 1.72.568 2.144.98.34.314.51.712.51 1.195 0 .399-.156.74-.468 1.024-.424.37-1.068.555-1.931.555-.864 0-1.522-.185-1.974-.555-.397-.313-.609-.753-.637-1.322H0c.057.938.396 1.706 1.019 2.304.566.54 1.302.91 2.207 1.109V16H5.88z" fill-rule="nonzero"/></svg>
              <span>{{ productCard.buyBtn }}</span>
            </div>
          </router-link>
          <!-- <div
            v-if="productCard.bookLink == '#'"
            class="productcard__links__btn inquire buy"
            :data-fevo-offer-id="productCard.offerId"
            is="fevo-offer-button"
          >
            <div class="holder" @click="buyFloodlight(productCard.buyTracking)">
              <svg width="9" height="16" viewBox="0 0 9 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.88 16v-1.92c.934-.185 1.67-.526 2.207-1.024.609-.555.913-1.266.913-2.133 0-.797-.212-1.472-.637-2.027-.354-.455-.87-.846-1.55-1.173-.495-.242-1.153-.47-1.973-.683-.977-.256-1.663-.533-2.06-.832-.396-.299-.594-.661-.594-1.088 0-.498.213-.9.637-1.205.425-.306 1.005-.459 1.74-.459 1.444 0 2.187.626 2.23 1.877h1.952c-.028-.796-.276-1.493-.743-2.09-.51-.64-1.217-1.067-2.122-1.28V0H3.226v1.92c-.891.185-1.613.548-2.165 1.088A2.85 2.85 0 00.17 5.12c0 .967.382 1.764 1.146 2.39.665.54 1.663.966 2.993 1.28 1.005.241 1.72.568 2.144.98.34.314.51.712.51 1.195 0 .399-.156.74-.468 1.024-.424.37-1.068.555-1.931.555-.864 0-1.522-.185-1.974-.555-.397-.313-.609-.753-.637-1.322H0c.057.938.396 1.706 1.019 2.304.566.54 1.302.91 2.207 1.109V16H5.88z" fill-rule="nonzero"/></svg>
              <span>{{ productCard.buyBtn }}</span>
            </div>
          </div> -->
          <!-- <a
            v-else
            class="productcard__links__btn buy"
            @click="inquireModalShow = !inquireModalShow"
          > -->
          <a
            v-else
            class="productcard__links__btn buy"
            @click="openLightbox(), buyFloodlight(productCard.inquireTracking)"
          >
            <svg width="24" height="19" viewBox="0 0 24 19" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'inquireNow'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M21.094 18.9c.637 0 1.181-.236 1.631-.707.45-.472.675-1.028.675-1.67V2.377c0-.642-.225-1.198-.675-1.67A2.173 2.173 0 0021.094 0H2.306C1.67 0 1.125.236.675.707A2.338 2.338 0 000 2.377v14.146c0 .642.225 1.198.675 1.67.45.471.994.707 1.631.707h18.788zM11.7 8.29L2.306 2.377h18.788L11.7 8.29zm9.394 8.233H2.306V4.753L11.7 10.61l9.394-5.857v11.77z" fill-rule="nonzero"/></svg>
            <span>Inquire Now</span>
          </a>
          <router-link
            :to="productCard.exploreLink"
            class="productcard__links__btn"
          >
            <div
              class="holder"
              @click="buyFloodlight(productCard.exploreTracking)"
            >
              <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.012 12.024l6.012-6.012L6.012 0 4.95 1.062l4.194 4.212H0V6.75h9.144L4.95 10.962z" fill-rule="nonzero"/></svg>
              <span>Explore</span>
            </div>
          </router-link>
        </div>
        <b-modal id="modal-1" v-model="productCard.modalVideo">
          <div class="modal-carousel-container">
            <vueVimeoPlayer
              @ready="onReady"
              ref="player"
              :video-id="productCard.videoID"
              autoplay="true"
              background="true"
            ></vueVimeoPlayer>
            <div
              class="modal__close"
              @click="productCard.modalVideo = !productCard.modalVideo"
            >
              <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
            </div>
          </div>
        </b-modal>
      </div>
      <b-modal id="modal-5" v-model="inquireModalShow">
        <div class="modal__close" @click="inquireModalShow = !inquireModalShow">
          <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.019 16a.947.947 0 00.695-.286.947.947 0 00.286-.695c0-.274-.1-.501-.298-.684L9.392 8l6.31-6.335A.888.888 0 0016 .98a.947.947 0 00-.286-.695A.947.947 0 0015.02 0c-.274 0-.501.1-.684.298L8 6.608 1.665.299A.888.888 0 00.98 0a.947.947 0 00-.695.286A.947.947 0 000 .98c0 .274.1.501.298.684L6.608 8l-6.31 6.335A.888.888 0 000 15.02c0 .273.095.505.286.695.19.19.422.286.695.286.274 0 .501-.1.684-.298L8 9.366l6.335 6.336c.183.199.41.298.684.298z" fill="#FFF" fill-rule="nonzero"/></svg>
        </div>
        <iframe
          src="https://usopen.formstack.com/forms/hospitality_luxury_suites"
          title="Hospitality Luxury Suites"
        ></iframe>
      </b-modal>
      <!-- Fifth card for layout screens above 1699px. Will need to change if content changes -->
      <div class="productcard tennis-tours" v-if="isTravelPackage && !fullCard">
        <b-carousel :interval="4000">
          <div class="productcard__gloss z2"></div>
          <b-carousel-slide>
            <template v-slot:img>
              <img
                :src="require('@/assets/images/steve-furgal_3.jpg')"
                alt="image slot"
              />
            </template>
          </b-carousel-slide>
          <div class="productcard__price__mobile">
            <p></p>
          </div>
        </b-carousel>
        <div class="productcard__price price-available">
          <p></p>
        </div>
        <div class="productcard__desc">
          <h2>Steve Furgal's International Tennis Tours</h2>
          <p
            class="furgal"
            v-html="epitch['productCardDescriptionPackage6']"
          ></p>
          <ul
            v-for="(packageData, index) in epitch['package6']"
            :key="'package' + index"
          >
            <li>{{ packageData["description"] }}</li>
          </ul>
        </div>
        <div class="productcard__links">
          <a
            class="productcard__links__btn fullwidthbtn"
            @click="buyFloodlight(productCard.exploreTracking)"
            href="https://onlocationexp.com/tennis/us-open-tennis-tickets?utm_source=hospitality.usopen.org&utm_medium=referral&utm_campaign=us_open-travel_packages_main_menu"
            target="_blank"
          >
            <svg width="12" height="12" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.012 12.024l6.012-6.012L6.012 0 4.95 1.062l4.194 4.212H0V6.75h9.144L4.95 10.962z" fill-rule="nonzero"/></svg>
            <span>Explore</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from "vue-vimeo-player";
import axios from "axios";

export default {
  name: "ProductsHalfColumn",
  components: {
    vueVimeoPlayer,
  },
  props: {
    productCard: {
      type: Array,
      required: true,
    },
    fullCard: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      playerReady: false,
      inquireModalShow: false,
      prices: null,
      epitch: null,
      dynamicProductCard: [],
      isTravelPackage: false,
    };
  },
  methods: {
    onReady() {
      this.playerReady = true;
    },
    getPrices() {
      let pages = this.$store.state.epitch.ePitch.pages;
      console.log("pages", this.$store.state.epitch);
      pages.forEach((page) => {
        if (page.id === "1") {
          let copy = JSON.parse(page.copy);
          this.prices = copy["pricingList"];
          this.prices?.forEach((price) => {
            switch (price.pricingType) {
              case "na":
                price.priceClass = "price-na";
                break;
              case "inquire":
                price.priceClass = "price-inquire";
                break;
              default:
                price.priceClass = "price-available";
                break;
            }
          });
        }
      });
    },
    epitchDynamicCard() {
      let pages = this.$store.state.epitch.ePitch.pages;

      let pagesHashMap = {};

      pages.forEach((page) => {
        pagesHashMap[page.id] = true;
        if (page.id === "7") {
          this.isTravelPackage = true;
        }
      });

      this.productCard.forEach((card) => {
        if (typeof pagesHashMap[card.epitchPageId] !== "undefined") {
          this.dynamicProductCard.push(card);
        }
      });

      // window.console.log("dynamicProductCard", this.dynamicProductCard);
      // window.console.log("epitch", this.epitch);
      // window.console.log("prices", this.prices);
    },
    buyFloodlight(id) {
      window.gtagClick(id);
    },
    openLightbox() {
      window.fsLightbox2933987.show();
    },
  },
  created() {
    this.getPrices();

    let pages = this.$store.state.epitch.ePitch.pages;
    if (typeof pages !== "undefined") {
      pages.forEach((page) => {
        if (page.id === "1") {
          this.epitch = JSON.parse(page.copy);
        }
        // window.console.log('epitch', this.epitch)
      });
    }
  },
  filters: {
    toCurrency: function (value) {
      if (typeof parseFloat(value) !== "number") {
        return value;
      }
      var formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2,
      });
      return formatter.format(value);
    },
  },
  computed: {},
  mounted() {
    this.epitchDynamicCard();
  },
};
</script>

<style lang="scss">
.ProductsHalfColumn {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (min-width: 1699px) {
    align-items: center;
    justify-content: center;
    width: 1500px;
  }
  .productcard {
    width: 49%;
    height: 700px;
    position: relative;
    overflow: hidden;
    border-radius: 5px 5px 0 0;
    box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.15);
    margin: 13px 0;
    @media screen and (min-width: 1699px) {
      width: 32%;
      margin: 13px 6px;
    }
    @media screen and (max-width: $md) {
      width: 100%;
    }
    @media screen and (max-width: $sm) {
      height: auto;
    }
    .carousel {
      img {
        width: 100%;
        object-fit: cover;
        @media (min-width: 1699px) {
          height: 240px;
        }
        @media (max-width: 1698px) {
          height: 271.938px;
        }
        @media (max-width: $xl) {
          height: 227.844px;
        }
        @media (max-width: $lg) {
          height: 169.047px;
        }
        @media (max-width: $md) {
          height: 255px;
        }
        @media (max-width: $sm) {
          height: calc((100vw - 30px) * 0.46789);
        }
      }
    }
    &__gloss {
      position: absolute;
      top: 0;
      left: -10%;
      width: 25%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.25);
      transform: skewX(-20deg);
    }
    &__price {
      visibility: hidden;
      position: absolute;
      right: 20px;
      top: 260px;
      min-width: 142px;
      height: 37px;
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-radius: 3px 3px 0 0;
      box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.15);
      border-bottom: 3px solid $cta-blue;
      p {
        padding: 0 10px;
      }
      @media screen and (min-width: 1699px) {
        top: 228px;
        right: 10px;
        width: 125px;
      }
      @media screen and (max-width: $xl) {
        top: 215px;
      }
      @media screen and (max-width: $lg) {
        top: 160px;
        right: 10px;
        width: 115px;
      }
      @media screen and (max-width: $md) {
        top: 240px;
        right: 15px;
      }
      @media screen and (max-width: $sm) {
        display: none;
      }
      &__mobile {
        display: none;
        @media screen and (max-width: $sm) {
          visibility: hidden;
          display: block;
          position: absolute;
          bottom: -20px;
          right: 10px;
          min-width: 115px;
          height: 37px;
          background-color: #fff;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 3px 3px 0 0;
          box-shadow: 0 7px 14px 0 rgba(0, 0, 0, 0.15);
          border-bottom: 3px solid $cta-blue;
          p {
            font-size: 12px;
            padding: 0 10px;
            color: $text-black;
            @media screen and (max-width: $lg) {
              font-size: 10px;
            }
            span {
              color: $cta-blue;
            }
          }
        }
      }
      p {
        font-size: 12px;
        color: $text-black;
        @media screen and (max-width: $lg) {
          font-size: 10px;
        }
        span {
          color: $cta-blue;
        }
      }
    }
    &__desc {
      padding: 37px;
      background-color: #fff;
      @media screen and (max-width: $sm) {
        padding: 37px 20px;
      }
      h2 {
        font-family: "interstate", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 17px;
        color: $text-black;
      }
      p {
        font-style: italic;
        font-size: 14px;
        color: $text-grey;
        // width: 90%;
        margin-top: 10px;
        margin-bottom: 20px;
        line-height: 1.5rem;
        @media screen and (max-width: $lg) {
          // width: 90%;
        }
      }
      .furgal {
        margin-bottom: 40px;
      }
      h3 {
        font-family: "interstate", sans-serif;
        font-weight: 700;
        font-style: normal;
        font-size: 17px;
        color: $text-black;
        margin-bottom: 20px;
      }
      ul {
        padding-left: 18px;
        // margin-top: 30px;
        font-style: italic;
        font-size: 13px;
        color: $text-grey;
        font-style: italic;
        li {
          margin: 4px 0;
          @media screen and (max-width: $sm) {
            margin-bottom: 20px;
            width: 100%;
          }
          &:last-child {
            margin: 0;
            @media screen and (max-width: $sm) {
              margin-bottom: 65px;
            }
          }
        }
      }
    }
    &__links {
      display: flex;
      justify-content: space-evenly;
      align-items: stretch;
      justify-content: center;
      box-shadow: 0px 0 20px rgba(0, 0, 0, 0.1);
      position: absolute;
      bottom: 0;
      width: 100%;
      &__btn {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        border-right: 1px solid #c9c9c9;
        background-color: #fff;
        height: 71px;
        padding-bottom: 2px;
        cursor: pointer;
        &.fullwidthbtn {
          width: 100%;
          &:after {
            width: 100%;
          }
        }
        &.buy {
          &:after {
            border-bottom: 5px solid $cta-blue;
            transition: all 0.3s ease-in-out;
          }
          span {
            color: $cta-blue;
            transition: all 0.3s ease-in-out;
          }
          svg {
            fill: $cta-blue;
            transition: all 0.3s ease-in-out;
          }
          &:hover {
            span {
              color: #3572b1;
            }
            svg {
              fill: #3572b1;
            }
            &:after {
              border-bottom: 5px solid #3572b1;
            }
          }
        }
        &:hover {
          text-decoration: none;
          background: #fff;
          &:after {
            border-bottom: 5px solid $cta-blue;
          }
          svg {
            fill: $cta-blue;
          }
          span {
            text-decoration: none;
            color: $cta-blue;
          }
        }
        &:last-child {
          border-right: none;
        }
        &:after {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          display: block;
          margin: 0 auto;
          width: 101%;
          border-bottom: 5px solid $text-black;
          transition: all 0.2s ease-in-out;
        }
        svg {
          margin-right: 10px;
          margin-left: -4px;
          fill: #bbb;
          transition: all 0.2s ease-in-out;
          @media screen and (max-width: $sm) {
            margin-right: 3px;
          }
        }
        .inquireNow {
          height: 12px;
        }
        span {
          font-family: "interstate", sans-serif;
          font-weight: 700;
          font-style: normal;
          color: $text-black;
          font-size: 11px;
          text-transform: uppercase;
          transition: all 0.2s ease-in-out;
        }
        .holder {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }
    .show {
      visibility: visible;
    }
    .noshow {
      // visibility: hidden;
      display: none;
      span {
        @media (min-width: 1700px) {
          width: 50px;
          line-height: 14px;
          // text-align: center;
        }
      }
    }
    .buy,
    .inquire {
      display: none;
    }
  }
  .price-inquire {
    .productcard__price {
      visibility: visible;
      &__mobile {
        visibility: visible;
      }
    }
    .productcard__links {
      .noshow {
        display: flex;
      }
      .inquire {
        display: none;
      }
    }
  }
  .price-available {
    .productcard__price {
      visibility: visible;
      &__mobile {
        visibility: visible;
      }
    }
    .productcard__links {
      .buy {
        display: flex;
      }
      &__btn {
        outline: none;
        border-top: none;
        border-left: none;
        border-bottom: none;
        .holder {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  /* Bootstrap Overrides */
  .carousel-control-prev {
    z-index: 3 !important;
  }

  .carousel-control-next-icon {
    background-image: url("~@/assets/icons/home/icon_gallery-arrow.svg");
  }

  .carousel-control-prev-icon {
    background-image: url("~@/assets/icons/home/icon_gallery-arrow.svg");
    transform: rotate(180deg);
  }

  .carousel-control-prev,
  .carousel-control-next {
    opacity: 1;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 18px;
    height: 34px;
  }

  .carousel-indicators {
    bottom: 22px;
  }

  .carousel-indicators li {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin: 0 8px;
    opacity: 1;
    background-color: #fff;
    border: 1px solid #fff;
  }

  .carousel-indicators li.active {
    background-color: transparent;
  }

  .carousel-indicators li:focus {
    outline: none;
  }

  .carousel-inner {
    @media screen and (max-width: $sm) {
      overflow: visible;
    }
  }

  .carousel-item {
    transition: all 0.6s cubic-bezier(0.93, 0.05, 0.05, 0.93) !important;
  }
}
</style>