export const getFullCardData = () => {
  return [
    {
      carouselImg1: "steve-furgal_1.jpg",
      title: `Steve Furgal's International Tennis Tours`,
      desc: `Offical Travel Partner of the USTA and the US Open`,
      points: `<li>Prime viewing from in-stadium balcony seating</li>
                    <li>Air-conditioned, lushy appointed lounge</li>`,
      videoLink: "#",
      bookLink: "#",
      exploreLink:
        "https://onlocationexp.com/tennis/us-open-tennis-tickets?utm_source=hospitality.usopen.org&utm_medium=referral&utm_campaign=us_open-travel_packages_main_menu",
    },
  ];
};
