<template>
  <div id="PremiumProductsGrid">
    <ProductHalfColumn :productCard="productCard" :fullCard="fullCard" />
    <ProductFullColumn
      v-if="isTravelPackage && fullCard"
      :productCardFull="productCardFull"
    />
  </div>
</template>

<script>
import { getHalfCardData } from "@/data/homeHalfCardData";
import { getFullCardData } from "@/data/homeFullCardData";
import ProductHalfColumn from "@/components/patterns/ProductHalfColumn";
import ProductFullColumn from "@/components/patterns/ProductFullColumn";

export default {
  name: "PremiumProductsGrid",
  components: {
    ProductHalfColumn,
    ProductFullColumn,
  },
  data() {
    return {
      productCard: getHalfCardData(),
      productCardFull: getFullCardData(),
      isTravelPackage: false,
      windowWidth: window.innerWidth,
      pagesArray: 0,
      fullCard: false,
    };
  },
  mounted() {
    this.epitchDynamicCard();
    this.setCardNumber(window.innerWidth);

    let that = this;
    window.addEventListener(
      "resize",
      function () {
        that.windowWidth = window.innerWidth;
        that.setCardNumber(window.innerWidth);
      }
      //   false
    );
  },
  methods: {
    epitchDynamicCard() {
      let pages = this.$store.state.epitch.ePitch.pages;

      let pagesHashMap = {};
      // console.log("pages", pages);

      pages.forEach((page) => {
        pagesHashMap[page.id] = true;
        if (page.id === "7") {
          this.isTravelPackage = true;
        }
      });

      pages.forEach((page) => {
        if (page.id !== "1" && page.id !== "1001" && page.id !== "11") {
          this.pagesArray += 1;
        }
      });
    },
    setCardNumber(width) {
      if (width > 1700 && (this.pagesArray == 4 || this.pagesArray == 7)) {
        this.fullCard = true;
      } else if (
        width > 1700 &&
        !(this.pagesArray == 4 || this.pagesArray == 7)
      ) {
        this.fullCard = false;
      } else if (width > 768 && this.pagesArray % 2 == 1) {
        this.fullCard = true;
      } else {
        this.fullCard = false;
      }
      // console.log("full", this.fullCard);
    },
  },
};
</script>

<style lang="scss" scoped>
#PremiumProductsGrid {
  @media (min-width: 1699px) {
    width: 1500px;
  }
}
</style>