export const getHalfCardData = () => {
  return [
    {
      slideImgs: [
        {
          carouselImg: "theOverlook/Carousel2/overlook-1-2000x1000.jpg",
        },
        {
          carouselImg: "theOverlook/Carousel2/overlook-2-2000x1000.jpg",
        },
        {
          carouselImg: "theOverlook/Carousel2/overlook-3-2000x1000.jpg",
        },
        {
          carouselImg: "theOverlook/Carousel2/overlook-4-2000x1000.jpg",
        },
        {
          carouselImg: "theOverlook/Carousel2/overlook-5-2000x1000.jpg",
        },
        // {
        //   carouselImg: "theOverlook/Carousel2/overlook-6-2000x1000.jpg",
        // },
        {
          carouselImg: "theOverlook/Carousel2/overlook-7-2000x1000.jpg",
        },
      ],
      className: "overlook-package",
      price: `Price from <span>$550</span>`,
      title: "The Overlook at the US Open",
      desc: `An indoor-outdoor contemporary, social oasis overlooking the US&nbsp;Open&nbsp;grounds`,
      header: "Ticket Package Includes:",
      points: `<li>A Loge seat in Arthur&nbsp;Ashe&nbsp;Stadium or a Courtside seat in Louis&nbsp;Armstrong&nbsp;Stadium</li>
                    <li>Exclusive access to the US Open's largest hospitality club</li>
                    <li>All-inclusive dining &amp; all-inclusive beer and wine</li>`,
      videoActive: true,
      modalVideo: false,
      // videoID: "709646331",
      videoID: "809790231",
      videoOptions: {
        background: true,
        muted: false,
      },
      bookLink: "#",
      buyBtn: "Buy Now",
      buyTracking: "DC-10973038/butto0/kpi_u00+standard",
      exploreTracking: "DC-10973038/butto0/kpi_u000+standard",
      offerId: "fv-offer--20230414-RJV-607",
      exploreLink: "/theoverlook",
      externalLink: false,
      name: "theoverlook",
      backendId: 0,
      epitchPageId: 2,
    },
    {
      slideImgs: [
        {
          carouselImg: "1968Room/Carousel2/1968-1-2000x1000.jpg",
        },
        {
          carouselImg: "1968Room/Carousel2/1968-2-2000x1000.jpg",
        },
        {
          carouselImg: "1968Room/Carousel2/1968-3-2000x1000.jpg",
        },
        {
          carouselImg: "1968Room/Carousel2/1968-4-2000x1000.jpg",
        },
        {
          carouselImg: "1968Room/Carousel2/1968-5-2000x1000.jpg",
        },
        {
          carouselImg: "1968Room/Carousel2/1968-6-2000x1000.jpg",
        },
        {
          carouselImg: "1968Room/Carousel2/1968-7-2000x1000.jpg",
        },
        {
          carouselImg: "1968Room/Carousel2/1968-8-2000x1000.jpg",
        },
        {
          carouselImg: "1968Room/Carousel2/1968-9-2000x1000.jpg",
        },
      ],
      className: "room-1968",
      price: `Price from <span>$1,250</span>`,
      title: "1968 Room",
      desc: `An exclusive lounge situated within the Overlook, the 1968&nbsp;Room is the pinnacle of US Open access and hospitality offering a stylish, intimate space for private dining`,
      header: "Ticket Package includes:",
      points: `<li>A Courtside seat in Arthur&nbsp;Ashe&nbsp;Stadium</li>
                    <li>Air-conditioned, plushly appointed 1968&nbsp;Room with rooftop bar + access to the Overlook&nbsp;at&nbsp;the&nbsp;US&nbsp;Open</li>
                    <li>All-inclusive lunch or dinner service as well as beer, fine wine, champagne and premium spirits</li>`,
      videoActive: true,
      modalVideo: false,
      // videoID: "709646331",
      videoID: "809790231",
      videoOptions: {
        background: true,
        muted: false,
      },
      bookLink: "#",
      buyBtn: "Buy Now",
      offerId: "fv-offer--20230414-HAV-607",
      exploreLink: "/1968room",
      name: "1968room",
      buyTracking: "DC-10973038/butto0/kpi_u001+standard",
      exploreTracking: "DC-10973038/butto0/kpi_u002+standard",
      externalLink: false,
      backendId: 1,
      epitchPageId: 3,
    },
    //
    //
    {
      slideImgs: [
        // {
        //   carouselImg: "courtsidePremier/Carousel/courtside-10.jpg",
        // },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-01.jpg",
        },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-02.jpg",
        },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-03.jpg",
        },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-04.jpg",
        },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-05.jpg",
        },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-06.jpg",
        },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-07.jpg",
        },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-08.jpg",
        },
        {
          carouselImg: "courtsidePremier/Carousel/courtside-09.jpg",
        },
      ],
      className: "courtside",
      price: "Inquire for Pricing",
      title: "Courtside Premier",
      header: "Courtside Premier package includes:",
      desc: `This is the pinnacle hospitality experience at the US Open featuring prime courtside seating in the front rows, elevated food & beverage, behind the scenes access, and dedicated service throughout your time at the tournament.`,
      points: `<li>Prime Courtside seat in Arthur Ashe Stadium</li>
                    <li>Dedicated Concierge Service with in-seat beverage and snack service</li>
                    <li>Parking pass or chauffeur car service with special grounds entrance</li>
                    <li>Private Practice Court Viewing Area & more</li>`,
      videoActive: false,
      modalVideo: false,
      bookLink: "modal",
      buyBtn: "Inquire Now",
      exploreLink: "/courtsidepremier",
      // inquireTracking: "DC-10973038/butto0/kpi_u003+standard",
      inquireTracking: "",
      // exploreTracking: "DC-10973038/butto0/kpi_u004+standard",
      exploreTracking: "",
      name: "courtsidepremier",
      externalLink: false,
      backendId: 6,
      epitchPageId: 12,
    },
    //
    //
    {
      slideImgs: [
        {
          carouselImg: "Suites/Carousel/suites_gallery_0.jpg",
        },
        {
          carouselImg: "Suites/Carousel/suites_gallery_1.jpg",
        },
        {
          carouselImg: "Suites/Carousel/suites_gallery_2.jpg",
        },
        {
          carouselImg: "Suites/Carousel/suites_gallery_3.jpg",
        },
        {
          carouselImg: "Suites/Carousel/suites_gallery_4.jpg",
        },
        {
          carouselImg: "Suites/Carousel/suites_gallery_5.jpg",
        },
        {
          carouselImg: "Suites/Carousel/suites_gallery_6.jpg",
        },
        {
          carouselImg: "Suites/Carousel/suites_gallery_7.jpg",
        },
      ],
      className: "luxury-suites",
      price: "Inquire for Pricing",
      title: "Luxury Suites",
      header: "Luxury Suites are available per session and include:",
      desc: `Private exterior balcony & interior lounge within Arthur&nbsp;Ashe&nbsp;Stadium offers prime viewing and an unparalleled experience`,
      points: `<li>Prime viewing from air-conditioned private lounge + outdoor balcony seating</li>
                    <li>Customizable chef-curated menus with full services from a dedicated suite attendant</li>
                    <li>Private ensuite restroom</li>`,
      videoActive: false,
      modalVideo: false,
      bookLink: "modal",
      buyBtn: "Inquire Now",
      exploreLink: "/suites",
      inquireTracking: "DC-10973038/butto0/kpi_u003+standard",
      exploreTracking: "DC-10973038/butto0/kpi_u004+standard",
      name: "luxurysuites",
      externalLink: false,
      backendId: 2,
      epitchPageId: 4,
    },
    {
      slideImgs: [
        {
          carouselImg: "CenterCourtClub/Carousel/ccc_gallery_0.jpg",
        },
        {
          carouselImg: "CenterCourtClub/Carousel/ccc_gallery_1.jpg",
        },
        {
          carouselImg: "CenterCourtClub/Carousel/ccc_gallery_2.jpg",
        },
        {
          carouselImg: "CenterCourtClub/Carousel/ccc_gallery_3.jpg",
        },
        {
          carouselImg: "CenterCourtClub/Carousel/ccc_gallery_4.jpg",
        },
        {
          carouselImg: "CenterCourtClub/Carousel/ccc_gallery_8.jpg",
        },
      ],
      className: "center-court-club",
      price: "Inquire for Pricing",
      title: "American Express® Center Court Club",
      desc: `An all-inclusive, expansive court-facing suite offered on an individual seat basis`,
      header: "Access Includes:",
      points: `<li>Prime Arthur&nbsp;Ashe&nbsp;Stadium center court view</li>
                    <li>Air-conditioned, lushly appointed lounge includes multiple private ensuite restrooms</li>
                    <li>All-inclusive beer, wine &amp; spirits + chef curated menus</li>`,
      videoActive: false,
      modalVideo: false,
      // bookLink: "modal",
      bookLink: "#",
      // buyBtn: "Inquire Now",
      buyBtn: "Buy Now",
      exploreLink: "/americanexpressclub",
      name: "americanexpressclub",
      inquireTracking: "DC-10973038/butto0/kpi_u005+standard",
      exploreTracking: "DC-10973038/butto0/kpi_u006+standard",
      externalLink: false,
      backendId: 3,
      epitchPageId: 5,
    },
    {
      slideImgs: [
        {
          carouselImg:
            "PrivateHospitality/Carousel/private-hospitality_gallery_0.jpg",
        },
        {
          carouselImg:
            "PrivateHospitality/Carousel/private-hospitality_gallery_1.jpg",
        },
        {
          carouselImg:
            "PrivateHospitality/Carousel/private-hospitality_gallery_2.jpg",
        },
        {
          carouselImg:
            "PrivateHospitality/Carousel/private-hospitality_gallery_3.jpg",
        },
        {
          carouselImg:
            "PrivateHospitality/Carousel/private-hospitality_gallery_4.jpg",
        },
        {
          carouselImg:
            "PrivateHospitality/Carousel/private-hospitality_gallery_5.jpg",
        },
        {
          carouselImg:
            "PrivateHospitality/Carousel/private-hospitality_gallery_6.jpg",
        },
        {
          carouselImg:
            "PrivateHospitality/Carousel/private-hospitality_gallery_7.jpg",
        },
      ],
      className: "private-hospitality",
      price: `Inquire for Pricing`,
      title: "Private Hospitality Space",
      desc: `A customizable private lounge located within the Overlook&nbsp;at&nbsp;the&nbsp;US&nbsp;Open`,
      header: "Private Hospitality Space Incudes:",
      points: `<li>Access to all Overlook&nbsp;at&nbsp;the&nbsp;US&nbsp;Open amenities</li>
                    <li>Choice of custom private dining experience or all-inclusive dining &amp; bar service</li>
                    <li>Personalized, private space may accommodate 60-150 guests</li>`,
      videoActive: false,
      modalVideo: false,
      bookLink: "modal",
      buyBtn: "Inquire Now",
      exploreLink: "/privatehospitality",
      inquireTracking: "DC-10973038/butto0/kpi_u007+standard",
      exploreTracking: "DC-10973038/butto0/kpi_u008+standard",
      name: "privatehospitality",
      externalLink: false,
      backendId: 4,
      epitchPageId: 6,
    },
    {
      slideImgs: [
        {
          carouselImg: "playerClub/Carousel/img-cover.jpg",
        },
        {
          carouselImg: "playerClub/Carousel/img-gallery-2.jpg",
        },
        {
          carouselImg: "playerClub/Carousel/img-gallery-3.jpg",
        },
        {
          carouselImg: "playerClub/Carousel/img-gallery-4.jpg",
        },
        {
          carouselImg: "playerClub/Carousel/img-gallery-5.jpg",
        },
        {
          carouselImg: "playerClub/Carousel/img-gallery-6.jpg",
        },
        {
          carouselImg: "playerClub/Carousel/img-gallery-7.jpg",
        },
        {
          carouselImg: "playerClub/Carousel/img-gallery-8.jpg",
        },
      ],
      className: "playerCafe",
      price: "Inquire for Pricing",
      title: "Player Cafe Courtside Club",
      header: "Club Access Includes:",
      desc: `This is the pinnacle hospitality experience at the US Open featuring prime courtside seating in the front rows, elevated food & beverage, behind the scenes access, and dedicated service throughout your time at the tournament.`,
      points: `<li>Prime Courtside seat in Arthur Ashe Stadium</li>
                    <li>Dedicated Concierge Service with in-seat beverage and snack service</li>
                    <li>Parking pass or chauffeur car service with special grounds entrance</li>
                    <li>Private Practice Court Viewing Area & more</li>`,
      videoActive: false,
      modalVideo: false,
      bookLink: "modal",
      buyBtn: "Inquire Now",
      exploreLink: "/playercafecourtsideclub",
      // inquireTracking: "DC-10973038/butto0/kpi_u003+standard",
      inquireTracking: "",
      // exploreTracking: "DC-10973038/butto0/kpi_u004+standard",
      exploreTracking: "",
      name: "playercafecourtsideclub",
      externalLink: false,
      backendId: 7,
      epitchPageId: 13,
    },
    // {
    //     slideImgs: [
    //         {
    //             carouselImg: 'steve-furgal_1.jpg'
    //         }
    //     ],
    //     price: `Price from <span></span>`,
    //     title: `Steve Furgal's International Tennis Tours`,
    //     desc: `Offical Travel Partner of the USTA and the US Open`,
    //     points: `<li>With an emphasis on outstanding service and travel packages, our Official Travel Partner, Steve Furgal International Tennis Tous, leads the industry in developing world-class tennis tours across the world.</li>`,
    //     videoActive: false,
    //     modalVideo: false,
    //     // videoID: '',
    //     // videoOptions: {
    //     //     background: true,
    //     //     muted: false
    //     // },
    //     bookLink: '#',
    //     buyBtn: 'Inquire Now',
    //     exploreLink: 'https://www.tours4tennis.com/',
    //     externalLink: true,
    //     backendId: 5
    // }
  ];
};
